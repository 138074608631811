import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contact} from '../models/contact.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient) {

  }

  // public getAll$(): Observable<Contact[]> {
  //   return null;
  // }

  // public getOne$(idClient: number): Observable<Contact> {
  //   return null;
  // }

  // public createContact$(c: Contact): Observable<Contact> {
  //   return null;
  // }

  // public modifyContact$(c: Contact): Observable<Contact> {
  //   return null;
  // }

  public updateUserWeb(c: any): Observable<Contact> {
    return this.httpClient.put<Contact>(environment.api_tienda + 'contactos/usuario/usuario-web', c);
  }

  public getUserWeb(): Observable<Contact> {
    return this.httpClient.get<Contact>(environment.api_tienda + 'contactos/usuario');
  }
}
