<a class="volver" (click)="retorno()">
  <svg width="14" height="8" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.8125 1.90625L8 8.09375L14.1875 1.90625" stroke="#005a5a" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
  <span>Compromisos Comunidad {{ compromiso.community }}</span>
</a>
<section class="detail">
  <div class="module module--white pt-0">
    <div class="container">
      <div class="row">
        <h1 class="mat-typography__headline">{{ compromiso.title }}</h1>
        <div class="col-md-8 col-xl-9 pr-md-4">
          <!-- begin Detail Content -->
          <div class="text-content">
            <div class="text-content" innerHTML="{{ compromiso.description }}"></div>
          </div>
          <!-- begin Detail Content -->
        </div>
        <div class="col-md-4 col-xl-3 mt-4 mt-md-0 date__img__aside">
          <div class="detail__aside"></div>
          <div class="information-table">
            <p *ngIf="compromiso.descripcion">
              <b>Impulsan: </b>
              <span innerHTML="{{ compromiso.descripcion }}"></span>
            </p>
            <!--*ngIf="compromiso.impacto"-->
            <p *ngIf="compromiso.linkAlProyecto">
              <a href="{{ compromiso['linkAlProyecto'] }}" target="_blank" class="link"><strong>Más
                  Información</strong><br /></a>
            </p>

            <!-- <p *ngIf="compromiso.appliesToPersons || compromiso.appliesToCompanies || compromiso.appliesToServices "
              class="impact"> </p> -->
            <div class="informacion">
              <div class="texto">
                <h5>Aplica a: </h5>
                <h5>Dimensiones: </h5>
                <p>Comunidad: </p>
              </div>
              <div class="iconos">
                <div class="aplicaA">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet" matTooltip="Personas"
                    [ngStyle]="{'fill': compromiso.appliesToPersons ? 'currentColor' : '#cfcfcf'}">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                      <path d="M2430 4671 c-73 -23 -125 -57 -183 -120 -154 -165 -149 -420 12 -582
                      170 -169 432 -170 600 -2 129 130 162 312 86 481 -28 61 -110 151 -169 186
                      -96 56 -239 72 -346 37z" />
                      <path d="M802 3620 c-84 -20 -162 -116 -162 -198 0 -92 65 -185 146 -210 31
                      -9 184 -12 588 -12 l546 0 0 -1302 c0 -1239 1 -1305 19 -1343 72 -158 295
                      -167 381 -15 l25 45 3 562 3 563 209 0 209 0 3 -563 3 -562 25 -45 c67 -119
                      225 -147 327 -57 20 18 44 50 54 72 18 38 19 104 19 1343 l0 1302 546 0 c404
                      0 557 3 588 12 57 17 119 82 135 141 27 95 -13 195 -97 244 l-47 28 -1745 2
                      c-960 0 -1760 -2 -1778 -7z" />
                    </g>
                  </svg>
                  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    matTooltip="Empresas"
                    [ngStyle]="{'fill': compromiso.appliesToCompanies? 'currentColor' : '#cfcfcf'}">
                    <path
                      d="M2 2h14v4h6v16H2V2zm18 6h-4v2h2v2h-2v2h2v2h-2v2h2v2h2V8zm-6-4H4v16h2v-2h6v2h2V4zM6 6h2v2H6V6zm6 0h-2v2h2V6zm-6 4h2v2H6v-2zm6 0h-2v2h2v-2zm-6 4h2v2H6v-2zm6 0h-2v2h2v-2z" />
                  </svg>

                  <svg width="800px" height="800px" viewBox="-4.5 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    matTooltip="Aplicaciones"
                    [ngStyle]="{'fill': compromiso.appliesToServices ? 'currentColor' : '#cfcfcf'}">>
                    <path
                      d="m12.75 0h-10.5c-1.243 0-2.25 1.008-2.25 2.25v19.5c0 1.243 1.008 2.25 2.25 2.25h10.5c1.243 0 2.25-1.007 2.25-2.25v-19.5c0-1.243-1.008-2.25-2.25-2.25zm-5.25 22.5c-.827-.001-1.497-.671-1.497-1.498s.671-1.498 1.498-1.498 1.498.671 1.498 1.498c0 .414-.168.788-.439 1.059-.271.271-.646.439-1.06.439h-.001zm5.25-5.06c-.002.308-.252.558-.56.56h-9.378c-.309 0-.56-.251-.56-.56v-14.627c0-.309.251-.56.56-.56h9.374c.308.002.558.252.56.56z" />
                  </svg>
                </div>
                <div class="dimensiones">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    matTooltip="Ambiente" [ngStyle]="{'stroke': compromiso.dimensionS ? 'currentColor' : '#cfcfcf'}"
                    class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    matTooltip="Social" [ngStyle]="{'stroke': compromiso.dimensionE ? 'currentColor' : '#cfcfcf'}"
                    class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    matTooltip="Gobernanza" [ngStyle]="{'stroke': compromiso.dimensionG ? 'currentColor' : '#cfcfcf'}"
                    class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                  </svg>
                </div>
                <div class="comunidad">
                  <p *ngIf="compromiso.community">
                    {{ compromiso.community }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>