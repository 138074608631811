import { Component, inject, Input } from '@angular/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';
import {
  CommunityDataAbstractService,
  communityDataToken,
} from '../../community-data';

@Component({
  selector: 'app-community-panel-header-it',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class CommunityPanelHeaderComponent {
  img_base = environment.img_base;
  communityService = inject<CommunityDataAbstractService>(communityDataToken);
  logoSrc = this.communityService.logoUrl;
  logoAlt = this.communityService.logoUrl;
}
