<section>
    <div class="separadorMain">
        <svg xmlns="http://www.w3.org/2000/svg" class="separador" viewBox="0 0 100 10" preserveAspectRatio="none">
            <polygon points="100 10 100 0 -4 10" fill="#005a5a"></polygon>
        </svg>
    </div>
    <div class="container">
        <div class="members">
            <div class="col one">
                <div class="row boardOfDirectors">
                    <h5>Comisión Directiva</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of boardOfDirectors">
                            <h6><span class="span">{{element.key}}</span>: {{element.value}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="row substitutes">
                    <h5>Suplentes</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of substitutes">
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="row trustee">
                    <h5>Síndico</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of trustee">
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="row sustitute">
                    <h5>Suplente</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of sustitute">
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>
                <div class="row sustitute">
                    <h5>Gerente Operativo</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of gerente_op">
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col two">
                <div class="row founders">
                    <h5>Fundadores</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of founders">
                            <i class="bi bi-dash"></i>
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="col three">
                <div class="row foundingCompanies">
                    <h5>Empresas fundadoras</h5>
                    <ul>
                        <li class="lista" *ngFor="let element of foundingCompanies">
                            <i class="bi bi-dash"></i>
                            <h6>{{element}}</h6>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="separadorMain">
        <svg xmlns="http://www.w3.org/2000/svg" class="separador" style="transform: rotate(180deg);"
            viewBox="0 0 100 10" preserveAspectRatio="none">
            <polygon points="100 10 100 0 -4 10" fill="#005a5a"></polygon>
        </svg>
    </div>
</section>