import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-plataform',
  templateUrl: './plataform.component.html',
  styleUrls: ['./plataform.component.scss'],
})
export class PlataformComponent implements OnInit {
  public user!: Keycloak.KeycloakProfile;
  isNavbarOpen = false;
  esSuscriptor: boolean = false;
  url_sincronia!: string;
  token: string = '';

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private suscripcionService: SuscripcionService,
  ) {
    this.keycloakService.loadUserProfile().then((u) => {
      this.user = u;
    });
    this.keycloakService.getToken().then((token) => (this.token = token));
    this.url_sincronia = environment.url_sincronia;
  }

  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  isNotMarketRoute(): boolean {
    return this.router.url !== '/market';
  }
}
