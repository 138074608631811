<section class="detail">
  <div class="module module--white pt-0">
    <div class="detail__media text-center mb-5">
      <div class="container">
        <div class="row">
          <h1 class="mat-typography__headline">{{elemento.nombre}}</h1>
          <div class="col-md-8 col-xl-9 pr-md-4 container-text">
            <div class="detail__header">
              <div class="detail__date" *ngIf="elemento.fechaInfoInicio || elemento.fechaInfoFin"></div>
            </div>
            <div class="text-container">
              <div class="text-content" [innerHTML]="elemento.descripcionHtml | safeHtml"></div>
            </div>
          </div>
          <div class="col-md-4 col-xl-3 mt-4 mt-md-0 date__img__aside">
            <img src="{{elemento['listadoImage']}}" alt="{{elemento.nombre}}">
            <div *ngIf="elemento['responsable_nombre'] || elemento['link_whapp'] || elemento['link_teams'] || elemento['link_gdrive']" class="information-table">
              <h5 *ngIf="elemento['responsable_nombre']">Coordinador: </h5>
              <button *ngIf="elemento['responsable_nombre'] || elemento['responsable_id']" (click)="openPartnerDialog()">{{elemento['responsable_nombre']}}</button> <!-- Botón para abrir el modal -->
 
              <h5 *ngIf="elemento['link_whapp'] || elemento['link_teams'] || elemento['link_gdrive']">Links: </h5>
              <a *ngIf="elemento['link_whapp']" href="{{elemento['link_whapp']}}" target="_blank">
                <p>Whatsapp</p>
              </a>
              <a *ngIf="elemento['link_teams']" href="{{elemento['link_teams']}}" target="_blank">
                <p>Videoconferencia</p>
              </a>
              <a *ngIf="elemento['link_gdrive']" href="{{elemento['link_gdrive']}}" target="_blank">
                <p>Documentos</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>