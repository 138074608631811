import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { SelectedComponentType } from '../../../types';

interface SelectedLink extends SelectedComponentType {
  icon: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class ImpactTradeSidebarComponent {
  public user!: Keycloak.KeycloakProfile;
  token: string = '';
  esSuscriptor: boolean = false;
  tieneSuscripcionesPendientes: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private suscripcionService: SuscripcionService,
    private keycloakService: KeycloakService,
  ) {
    this.keycloakService.loadUserProfile().then(u => {
      this.user = u;
    });

    this.keycloakService.getToken().then(token => (this.token = token));
  }

  @Input() selected: SelectedComponentType = {
    id: 'iniciativas',
    desc: 'Iniciativas',
  };
  @Output() select = new EventEmitter<SelectedComponentType>();

  listItems: SelectedLink[] = [
    { id: 'agenda', desc: 'Programa', icon: 'bi-calendar4-range' },
    { id: 'participantes', desc: 'Participantes', icon: 'bi-people-fill' },
    { id: 'iniciativas', desc: 'Iniciativas', icon: 'bi-rocket-takeoff' },
    { id: 'compromisos', desc: 'Compromisos', icon: 'bi-patch-check-fill' },
    { id: 'fotos', desc: 'Galería de Fotos', icon: 'bi-images' },
  ];

  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe(result => {
      this.esSuscriptor = result;
    });

    this.suscripcionService.hasPendingSuscription().subscribe(result => {
      this.tieneSuscripcionesPendientes = result;
    });
  }

  selectComp(comp: SelectedComponentType) {
    this.select.emit(comp);
    this.selected = comp;
  }

  openModal(texto: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.texto = texto;
  }

  buttonClick() {
    if (this.esSuscriptor) {
      window.open(
        `${environment.url_sincronia}user_id=${this.suscripcionService.idClienteConfirmado}&token=${this.token}`,
        '_blank',
      );
      return;
    }
    if (this.tieneSuscripcionesPendientes) {
      this.openModal(
        'Usted ya tiene una suscripcion hecha en estado pendiente de verificacion. Pronto le enviaremos novedades de la misma y como proseguir.',
      );
      return;
    }
    this.router.navigate(['/market']);
  }
}
