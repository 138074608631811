import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriasService {
  constructor(private httpClient: HttpClient) {}

  public getAll(): Observable<any[]> {
    let params = new HttpParams();

    return this.httpClient.get<any[]>(
      environment.api_base + environment.tenant + '/categorias',
      { params }
    );
  }
}
