import { Component, Injectable, Type } from '@angular/core';
import { ImpactTradeParticipantesComponent } from './participantes/participantes.component';
import { ImpactTradeIniciativasComponent } from './iniciativas/iniciativas.component';
import { ImpactTradeAgendaComponent } from './agenda/agenda.component';
import { ImpactTradeFotosComponent } from './fotos/fotos.component';
import { ImpactTradeCompromisosComponent } from './compromisos/compromisos.component';

@Injectable({ providedIn: 'root' })
export class ContentService {
  contents = {
    participantes: ImpactTradeParticipantesComponent,
    iniciativas: ImpactTradeIniciativasComponent,
    agenda: ImpactTradeAgendaComponent,
    fotos: ImpactTradeFotosComponent,
    compromisos: ImpactTradeCompromisosComponent
  };
  getContents() {
    return this.contents;
  }
}
