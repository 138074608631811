import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
})
export class SvgIconComponent implements OnInit {
  @Input() iconType: string = 'person'; // Tipo de ícono (person, company, etc.)
  @Input() color: string = '#cfcfcf'; // Color por defecto

  constructor() {}

  ngOnInit(): void {}
}
