import { Component, OnInit } from '@angular/core';
import { ElementService } from 'src/app/shared/services/element.service';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss'],
})
export class CommunitiesComponent implements OnInit {
  constructor(private elementService: ElementService) {}

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 1000;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }
    this.filtrar(false);
  }

  public filtro = new FormGroup({
    nombre: new FormControl(),
    institucion: new FormControl(),
    tipoModulo: new FormControl(),
    categoria3: new FormControl(),
  });

  public filtrar(cargarMas: boolean): void {
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroParticipantes: ElementoFiltro = new ElementoFiltro();
    filtroParticipantes.estado = 'activo';
    filtroParticipantes.modulo = 'Comunidades';
    filtroParticipantes.categorias = [];

    // Object.keys(this.filtro.value).forEach((key, index) => {
    //   if(key.includes('categoria')){
    //     if(!!this.filtro.value[key] && this.filtro.value[key]!='0')
    //     filtroParticipantes.categorias.push(this.filtro.value[key]);
    //   }
    //   else if (this.filtro.value[key]) {
    //     filtroParticipantes[key] = this.filtro.value[key];
    //   }
    // });
    this.elementService
      .getAll(
        this.limite,
        this.desplazamiento,
        filtroParticipantes,
        'nombre-asc',
      )
      .subscribe(data => {
        if (cargarMas) {
          (data as any)['elementos'].forEach((element: Elemento) => {
            this.elementos$.push(element);
          });
        } else {
          this.elementos$ = (data as any)['elementos'];
        }
        this.totalItems = (data as any)['total'];
      });
    this.pageIndex++;
  }
  public resetear(): void {
    this.filtro.reset();
    this.filtrar(false);
  }

  public loadMore() {
    this.desplazamiento = this.pageIndex * this.limite;
    this.filtrar(true);
  }
}
