<swiper-container
  navigation="{
    nextEl: '.button-next',
    prevEl: '.button-prev'
  }"
  pagination="true"
  slides-per-view="1"
  init="false"
  class="swiper-container"
>
  <swiper-slide class="elementCarousel" *ngFor="let image of this.images">
    <img class="image" [src]="image.src" alt="" />
  </swiper-slide>
</swiper-container>
