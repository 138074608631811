import { Component } from '@angular/core';
import { IAResponsableCommunityDataService } from './ia-responsable.service';
import { communityDataToken } from '../community-panel/community-data';

@Component({
  template: ` <app-community-panel></app-community-panel> `,
  providers: [
    {
      provide: communityDataToken,
      useClass: IAResponsableCommunityDataService,
    },
  ],
})
export class IAResponsableCommunity {}
