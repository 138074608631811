import { Component } from '@angular/core';

@Component({
  selector: 'app-sincronia',
  templateUrl: './sincronia.component.html',
  styleUrls: ['./sincronia.component.scss']
})
export class SincroniaComponent {
scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
