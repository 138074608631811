import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proyect-dialog',
  templateUrl: './proyect-dialog.component.html',
  styleUrls: ['./proyect-dialog.component.scss'],
})
export class ProyectDialogComponent {

  @Input() id!: string;

  elemento!: Elemento;

  img_base = environment.img_base;

  constructor(
    public modalService: NgbActiveModal,
    private elementService: ElementService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      var filtroDetalle: ElementoFiltro = new ElementoFiltro();
      filtroDetalle.id = Number.parseInt(this.id);
      this.elementService.getAll(0, 0, filtroDetalle, '').subscribe((data) => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(
            this.elemento.fechaInfoInicio
          );
        }
      });
    });
  }

  onCloseClick(): void {
    this.modalService.close();
  }
}
