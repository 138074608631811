import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateFn,
} from '@angular/router';
import { SuscripcionService } from '../services/suscripcion.service';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

export const SuscriptionGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  redirect: string = '/market',
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const suscribed = inject(SuscripcionService).isSuscriptorSync();
  if (!suscribed) inject(Router).navigate([redirect]);
  return suscribed;
};
