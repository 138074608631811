import { Component } from "@angular/core";
import { ElementService } from "src/app/shared/services/element.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-header-it',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class ImpactTradeHeaderComponent {
  constructor(private elementService: ElementService) {}

  img_base = environment.img_base;
}
