import { Component, Input, inject } from '@angular/core';
import { ContentService } from './content.service';
import { SelectedComponentType } from '../../../types';

@Component({
  selector: 'app-community-panel-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class CommunityPanelContentComponent {
  @Input() selected: SelectedComponentType = {
    id: 'participantes',
    desc: 'Participantes',
  };
  contentList = inject(ContentService).getContents();

  ngOnInit() {
    document.querySelector(`#${this.selected.id}`)?.scrollIntoView();
  }
}
