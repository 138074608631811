<div
  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-2"
>
  <h2>{{ infoCompromisos.title }}</h2>
  <div class="link-crear">
    <a [href]="infoCompromisos.linkProponer" target="_blank"
      ><span> Proponer un compromiso!</span><span class="ms-1">&rarr;</span></a
    >
  </div>
</div>
<p>
  {{ infoCompromisos.description }}
</p>
<!-- <div class="w-100 mb-4">
  <ul>
    <li *ngFor="let comp of compromisosList">
      <h4>{{ comp.title }}</h4>
      <p>{{ comp.desc }}</p>
    </li>
  </ul>
</div> -->

<app-commitment-list [compromisosList]="compromisosList"></app-commitment-list>

<button (click)="this.buttonClick()" class="button">
  <span>Ver Compromisos</span>
</button>
