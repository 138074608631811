import { Component } from '@angular/core';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comisiones',
  templateUrl: './comisiones.component.html',
  styleUrls: ['./comisiones.component.scss'],
})
export class ComisionesComponent {
  constructor(
    private elementService: ElementService,
    private suscripcionService: SuscripcionService,
    private router: Router,
  ) {}

  esSuscriptor: boolean = true;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 1000;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;

  //categorias$: Categoria[];
  //moduloTipos$: ModuloTipo[];

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }

    this.filtrar(false);
  }

  public filtrar(cargarMas: boolean): void {
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroComisiones: ElementoFiltro = new ElementoFiltro();
    filtroComisiones.estado = 'ACTIVO';
    filtroComisiones.modulo = 'Comisiones';

    this.elementService
      .getAll(this.limite, this.desplazamiento, filtroComisiones, 'nombre-asc')
      .subscribe(data => {
        if (cargarMas) {
          (data as any)['elementos'].forEach((element: Elemento) => {
            this.elementos$.push(element);
          });
        } else {
          this.elementos$ = (data as any)['elementos'];
        }
        this.totalItems = (data as any)['total'];
      });
    this.pageIndex++;
  }

  public loadMore() {
    this.desplazamiento = this.pageIndex * this.limite;
    this.filtrar(true);
  }
}
