<section>
    <div class="containerPyP">
        <div class="sub1">
            <h1 class="titulo">Programas y Proyectos</h1>
            <p class="text">Los programas y proyectos que tejen la trama de Fibras cruzan y entrelazan las diferentes
                dimensiones.</p>
            <p><a href="https://fibras.org/programas/">Accede a más información haciendo ClickAquí.</a></p>
            <a href="https://fibras.org/programas/"><img
                    src="/assets/img/imgs/Diagrama-ESP-1.png" class="imagen" alt=""
                    class="Imagen1PyP"></a>
        </div>
        <div class="sub2">
            <img src="/assets/img/imgs/logo.png" class="imagen2" alt="">
        </div>
    </div>
</section>
<div class="separadorMain">
    <svg xmlns="http://www.w3.org/2000/svg" class="separador" viewBox="0 0 100 10" preserveAspectRatio="none">
        <polygon points="100 10 100 0 -4 10" fill="#005a5a"></polygon>
    </svg>
</div>
<div style="background-color: var(--verde-1); height: 8px; width: 100%;"></div>