import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Compromiso } from 'src/app/shared/models/compromiso.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';

@Component({
  selector: 'app-communities-commitments',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss'],
})
export class CommunitiesCommitmentsComponent implements OnInit {
  compromisosList: Compromiso[] = [];

  currentFilter: string = 'Fibras';
  constructor(
    private compromisoService: CompromisoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.loadCompromisos();
    });
  }

  loadCompromisos(): void {
    this.compromisoService.getCompromisos(null).subscribe((data: any) => {
      this.compromisosList = data.commitments;
    });
  }


  setFilter(community: string) {
    this.currentFilter = community;
  }
}
