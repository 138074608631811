<main>
  <app-community-panel-header-it>
    <header-description>{{ info.description }}</header-description>
  </app-community-panel-header-it>
  <div class="container panel py-5 mt-n5 ph-10">
    <div class="row gap-lg-4">
      <div class="col-lg-3 sidebar">
        <app-community-panel-sidebar
          (select)="this.onSelectedComp($event)"
        ></app-community-panel-sidebar>
      </div>
      <div class="col-lg">
        <app-community-panel-content
          [selected]="this.selectedComponent"
        ></app-community-panel-content>
      </div>
    </div>
  </div>
</main>
