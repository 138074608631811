import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent {
  esSuscriptor: boolean = true;
  constructor(
    private suscripcionService: SuscripcionService,
    private keycloakService: KeycloakService
  ) {}
  ngOnInit() {
    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
    });
  }
  empresas = [
    {
      tipoEmpresa: 'Chicas',
      precio: 90,
      infoAdicional: 'Empresa Chica: < 1M USD de Facturación Anual',
    },
    {
      tipoEmpresa: 'Medianas',
      precio: 180,
      infoAdicional: 'Empresa Mediana: > 1M y < 5M USD de Facturación Anual',
    },
    {
      tipoEmpresa: 'Grandes',
      precio: 270,
      infoAdicional: 'Empresa Grande: > 5M USD de Facturación Anual',
    },
  ];
  comunidades = [
    {
      tipoComunidad: 'Chicas',
      precio: '1.000',
      infoAdicional: 'Comunidad Chica: hasta 100 miembros',
    },
    {
      tipoComunidad: 'Medianas',
      precio: '2.000',
      infoAdicional: 'Comunidad Mediana: hasta 200 miembros',
    },
    {
      tipoComunidad: 'Grandes',
      precio: '3.000',
      infoAdicional: 'Comunidad Grande: hasta 500 miembros',
    },
  ];
  getPopoverTriggers(): string {
    return this.isMobile() ? 'click' : 'mouseenter:mouseleave click';
  }

  private isMobile(): boolean {
    if (isPlatformBrowser(window)) {
      return window.innerWidth < 768;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {}
}
