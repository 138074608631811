export const environment = {
  production: false,
  api_base: 'https://test.aleph.work/cms/api/public/',
  api_tienda: 'https://test.aleph.work/api/',
  //api_fibras: 'https://fibras-dev.eastus.cloudapp.azure.com/backend/',
  // api_fibras: 'https://fibras-prod-app-001.eastus.cloudapp.azure.com/backend/',
  api_fibras: 'http://localhost:3000/backend/', //local

  tenant: 'fibras',
  img_base: 'https://test.aleph.work',
  idStore: 125,
  url_sincronia: 'http://fibras-dev.eastus.cloudapp.azure.com/view/homePage?',

  keycloak_url: 'https://test.aleph.work/auth',
  keycloak_realm: 'fibras',
  keycloak_clientId: 'cms',
  keycloak_idpHint: 'fibras-oidc',

  // version_1: 'v1/',
  // login: '/auth/realms/sistemab/account',
  // login: 'https://auth-aleph.quanam.com/auth/realms/sistemab/account',
  // ateneos: 'https://ateneo-aleph.quanam.com/ateneos/consejo-empresarial-b/home',
  // leybic: 'https://www.leybic.com/',
  // marketplace: '/marketplace',
  // consejo_b: 'https://consejoempresarialb.org/',
  // captchaKey: '6LdqTV4oAAAAABaaYPtSEDg3J8dpMX4xPZ-4wfdX'
};


