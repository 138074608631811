import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent {
  @Input() index!: number;
  @Input() images!: { src: string; thumb: string }[];

  ngOnInit() {
      const swiperEl = document.querySelector('swiper-container');
      //@ts-ignore
      swiperEl.initialSlide = this.index;
      //@ts-ignore
      const params = {
        injectStyles: [
          `
          .swiper-button-next, .swiper-button-prev {
            padding: 10px 5px 10px 12px;
            color: var(--verde-1);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: rgba(255,255,255, .5);
            text-align: center;
          }

          .swiper-button-prev {
            padding: 10px 12px 10px 5px;
          }

          .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background-color: var(--verde-1)
          }

          .swiper-horizontal {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          `,
        ]
      };
      //@ts-ignore
      Object.assign(swiperEl, params);
      swiperEl?.initialize();
  }
}
