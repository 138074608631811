import {
  inject,
  Injector,
  NgModule,
  runInInjectionContext,
} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree,
} from '@angular/router';
import { AuthGuard, AuthGuardFn } from './shared/components/auth.guard';
import { AboutUsComponent } from './modules/aboutus/aboutus.component';
import { AlliancesComponent } from './modules/alliances/alliances.component';
import { ComisionesComponent } from './modules/comisiones/comisiones.component';
import { ComisionDetailComponent } from './modules/comisiones/component/comision-detail/comision-detail.component';
import { CommitmentsComponent } from './modules/commitments/commitments.component';
import { CommitmentDetailComponent } from './modules/commitments/component/commitment-detail/commitment-detail.component';
import { CommunitiesCommitmentsComponent } from './modules/commitments/communities/communities.component';
import { CommunitiesComponent } from './modules/communities/communities.component';
import { ImpactTradeComponent } from './modules/communities/impact-trade/impact-trade.component';
import { NewsDetailComponent } from './modules/events/component/news-detail.component';
import { NewsComponent } from './modules/events/news.component';
import { FormularioComponent } from './modules/formulario/formulario.component';
import { HomeComponent } from './modules/home/home.component';
import { InvoicesPage } from './modules/invoices/invoices.page';
import { MarketComponent } from './modules/market/market.component';
import { PaymentsPage } from './modules/payments/payments.page';
import { PlataformComponent } from './modules/plataform/plataform.component';
import { ProyectDetailComponent } from './modules/proyects/component/proyect-detail.component';
import { ProyectsComponent } from './modules/proyects/proyects.component';
import { SincroniaComponent } from './modules/sincronia/sincronia.component';
import { ThankYouComponent } from './modules/thank-you/thank-you.component';
import { UserPage } from './modules/user/user.page';
import { CommunityPanelComponent } from './modules/communities/community-panel/community-panel.component';
import { CommunityCommitmentsComponent } from './modules/commitments/communities/community/community.component';
import { SuscriptionGuard } from './shared/components/suscription.guard';
import { concatMap, from, last, Observable, of, takeWhile } from 'rxjs';
import { IniciativaDetailComponent } from './modules/communities/impact-trade/component/content/iniciativas/details/iniciativa-detail.component';
import { IAResponsableCommunity } from './modules/communities/ia-responsable/ia-responsable.component';
import { ImpactTradeCommunity } from './modules/communities/impact-trade-v2/impact-trade.component';
//import { UserPage } from './modules/user/user.page';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'aboutus',
    component: AboutUsComponent,
  },
  {
    path: 'alliances',
    component: AlliancesComponent,
  },
  {
    path: 'comisiones',
    component: ComisionesComponent,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'comision-detail/:id',
    component: ComisionDetailComponent,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'commitments',
    component: PlataformComponent,
  },
  {
    path: 'commitments/:id',
    component: CommitmentsComponent,
  },
  {
    path: 'commitment-detail/:id',
    component: CommitmentDetailComponent,
  },
  {
    path: 'communities-commitments',
    component: CommunitiesCommitmentsComponent,
  },
  {
    path: 'community-commitments/:id',
    component: CommunityCommitmentsComponent,
  },
  {
    path: 'communities',
    component: CommunitiesComponent,
  },
  {
    path: 'communities/impact-trade',
    component: ImpactTradeCommunity,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'communities/iaresponsable',
    component: IAResponsableCommunity,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'communities-commitments',
    component: CommunitiesCommitmentsComponent,
  },
  {
    path: 'communities/:community/iniciativas-detail/:id',
    component: IniciativaDetailComponent,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'contact',
    component: FormularioComponent,
  },
  {
    path: 'invoices',
    component: InvoicesPage,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'market',
    component: MarketComponent,
  },
  {
    path: 'news',
    component: NewsComponent,
  },
  {
    path: 'news-detail/:id',
    component: NewsDetailComponent,
  },
  {
    path: 'payments',
    component: PaymentsPage,
    canActivate: [SequentialGuards([AuthGuardFn, SuscriptionGuard])],
  },
  {
    path: 'proyects',
    component: ProyectsComponent,
  },
  {
    path: 'proyect-detail/:id',
    component: ProyectDetailComponent,
  },
  {
    path: 'sincronia',
    component: SincroniaComponent,
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'user',
    component: UserPage,
    canActivate: [AuthGuardFn],
  },
];

export function SequentialGuards(guards: CanActivateFn[]): CanActivateFn {
  return (route, state) => {
    const injectionContext = inject(Injector);
    // Convert an array into an observable.
    return from(guards).pipe(
      // For each guard, fire canActivate and wait for it to complete.
      concatMap(guard => {
        return runInInjectionContext(injectionContext, () => {
          var guardResult = guard(route, state);
          if (guardResult instanceof Observable) {
            return guardResult;
          } else if (guardResult instanceof Promise) {
            return from(guardResult);
          } else {
            return of(guardResult);
          }
        });
      }),
      // Don't execute the next guard if the current guard's result is not true.
      takeWhile(value => value === true, true),
      // Return the last guard's result.
      last(),
    );
  };
}

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
