import { Component } from '@angular/core';

@Component({
  selector: 'app-ods',
  templateUrl: './ods.component.html',
  styleUrls: ['./ods.component.scss']
})

export class OdsComponent {
 ods = [
    {
      id:'1',
      image:'assets/img/ods/1.png',
      url:'https://www.un.org/sustainabledevelopment/es/poverty/',
    },
    {
      id:'2',
      image:'assets/img/ods/2.png',
      url:'https://www.un.org/sustainabledevelopment/es/hunger/',
    },
    {
      id:'3',
      image:'assets/img/ods/3.png',
      url:'https://www.un.org/sustainabledevelopment/es/health/',
    },
    {
      id:'4',
      image:'assets/img/ods/4.png',
      url:'https://www.un.org/sustainabledevelopment/es/education/',
    },
    {
      id:'5',
      image:'assets/img/ods/5.png',
      url:'https://www.un.org/sustainabledevelopment/es/gender-equality/',
    },
    {
      id:'6',
      image:'assets/img/ods/6.png',
      url:'https://www.un.org/sustainabledevelopment/es/water-and-sanitation/',
    },
    {
      id:'7',
      image:'assets/img/ods/7.png',
      url:'https://www.un.org/sustainabledevelopment/es/energy/',
    },
    {
      id:'8',
      image:'assets/img/ods/8.png',
      url:'https://www.un.org/sustainabledevelopment/es/economic-growth/',
    },
    {
      id:'9',
      image:'assets/img/ods/9.png',
      url:'https://www.un.org/sustainabledevelopment/es/infrastructure/',
    },
    {
      id:'10',
      image:'assets/img/ods/10.png',
      url:'https://www.un.org/sustainabledevelopment/es/inequality/',
    },
    {
      id:'11',
      image:'assets/img/ods/11.png',
      url:'https://www.un.org/sustainabledevelopment/es/cities/',
    },
    {
      id:'12',
      image:'assets/img/ods/12.png',
      url:'https://www.un.org/sustainabledevelopment/es/sustainable-consumption-production/',
    },
    {
      id:'13',
      image:'assets/img/ods/13.png',
      url:'https://www.un.org/sustainabledevelopment/es/climate-change-2/',
    },
    {
      id:'14',
      image:'assets/img/ods/14.png',
      url:'https://www.un.org/sustainabledevelopment/es/oceans/',
    },
    {
      id:'15',
      image:'assets/img/ods/15.png',
      url:'https://www.un.org/sustainabledevelopment/es/biodiversity/',
    },
    {
      id:'16',
      image:'assets/img/ods/16.png',
      url:'https://www.un.org/sustainabledevelopment/es/peace-justice/',
    },
    {
      id:'17',
      image:'assets/img/ods/17.png',
      url:'https://www.un.org/sustainabledevelopment/es/globalpartnerships/',
    },
  ]
}
