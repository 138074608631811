import { Component } from "@angular/core";

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class ImpactTradeAgendaComponent {


}
