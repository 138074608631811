<section class="detail">
  <div class="module module--white pt-0">
    <div class="detail__media text-center mb-5">
      <img *ngIf="elemento.backgroundImage" src="{{img_base}}{{elemento['backgroundImage']}}" alt="Imagen" />
      <iframe *ngIf="elemento.iframe" src="{{elemento.iframe}}" title="Video" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="container">
      <div class="row">
        <h1 class="mat-typography__headline">{{elemento.nombre}}</h1>
        <div class="col-md-8 col-xl-9 pr-md-4">
          <div class="detail__header">
            <div class="detail__date" *ngIf="elemento.fechaInfoInicio || elemento.fechaInfoFin">
            </div>
            <img src="{{img_base}}{{elemento['detailImage']}}">


          </div>

          <!-- begin Detail Content -->
          <div class="text-content">

            <div class="text-content" innerHTML="{{elemento.descripcionHtml}}"></div>


          </div>
          <!-- begin Detail Content -->
        </div>
        <div class="col-md-4 col-xl-3 mt-4 mt-md-0 date__img__aside">

          <div class="detail__aside"
            *ngIf="categoria1 || categoria2 || elemento.tipoInfo || elemento.fuente || elemento.institucion || elemento.pais || elemento.lenguaje">

          </div>
          <div class="information-table">
            <p *ngIf="elemento.descripcion"><b>Impulsan: </b> <span innerHTML="{{elemento.descripcion}}"></span></p>
            <!--*ngIf="elemento.impacto"-->
            <p *ngIf="elemento.linkAlProyecto"><a href="{{elemento['linkAlProyecto']}}" target="_blank"
                class="link"><strong>Más Información</strong><br></a></p>

            <p *ngIf="elemento.categorias | categoriaFilter: 'dimension'" class="impact">
              <strong>Dimensión:</strong><br><span>{{elemento.categorias | categoriaFilter: 'dimension'}}</span>
            </p>
            <p *ngIf="elemento.categorias | categoriaFilter: 'ods'" class="ods"><strong>ODS:</strong><br><span
                [innerHTML]="elemento.categorias | categoriaImgFilter: 'ods'"> </span>


            </p>

            <p class="stage" *ngIf="esSuscriptor && (elemento.categorias | categoriaFilter: 'etapa')">
              <strong>Etapa:</strong><br><span>{{elemento.categorias | categoriaFilter: 'etapa'}}</span>
            </p>

            <p *ngIf="elemento.link">
              <a href="{{elemento.link}}" target="_blank" rel="noopener noreferrer"><span>{{elemento.link}}</span></a>
            </p>

            <p *ngIf="elemento.archivo">
              <span><a href="{{elemento.archivo}}" target="_blank">Descargar información</a></span>
            </p>

            <p *ngIf="elemento.lenguaje" class="language"><strong>Lenguaje:</strong><span>{{elemento.lenguaje}}</span>
            </p>

            <div *ngIf="this.esSuscriptor" class="button-container">
              <a class="button-link" (click)="openModal(elemento.id)"><button>ESTADO DEL PROYECTO</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>