import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { ContactFormService } from 'src/app/shared/services/contactForm.service';

interface Errores {
  nombre: string;
  correo: string;
  asunto: string;
  mensaje: string;
}

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent {

 constructor(private contactFormService: ContactFormService, private modalService: NgbModal ){

 }

  nombre: string = '';
  correo: string = '';
  asunto: string = '';
  mensaje: string = '';

  errores: Errores = {
    nombre: '',
    correo: '',
    asunto: '',
    mensaje: '',
  };

  mostrarMensajeDeError: boolean = false;

  validarInput(elem: string, idCampo: keyof Errores, esMail: boolean = false): void {
    if (elem === '') {
        this.errores[idCampo] = 'El campo es obligatorio.';
    } else {
        if (esMail) {
            const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.errores[idCampo] = regexCorreo.test(elem) ? '' : 'Formato de correo electrónico inválido.';
        } else {
            this.errores[idCampo] = '';
        }
    }
  }

  openModal(texto: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.texto = texto;
  }

  enviarFormulario(): void {
    console.log('Enviando formulario...');

    // Validar campos solo después de enviar el formulario
    this.validarInput(this.nombre, 'nombre');
    this.validarInput(this.correo, 'correo', true);
    this.validarInput(this.asunto, 'asunto');
    this.validarInput(this.mensaje, 'mensaje');

    // Verificar si hay errores
    const hayErrores = Object.values(this.errores).some(error => error !== '');

    this.mostrarMensajeDeError = hayErrores;

    if (hayErrores) {
      // No enviar el formulario si hay errores
      console.log('Uno o más campos tienen errores. No se puede enviar el formulario.');
    } else {

      // Llamada al método send
      const params = {
        nombre: this.nombre,
        email: this.correo,
        asunto: this.asunto,
        notas: this.mensaje
        // Agrega aquí más propiedades si es necesario
      };

      this.contactFormService.send(params).subscribe(
        (response) => {
          // Lógica para enviar el formulario
          console.log('Formulario enviado correctamente.');
          console.log('Respuesta del servidor:', response);
          this.openModal(
            'Muchas gracias por tu tiempo! Su correo se ha enviado correctamente. Pronto nos estaremos comunicando con usted!'
          );
        },
        (error) => {
          console.error('Error al enviar el formulario:', error);
          this.openModal(
            'Hubo un error al enviar el correo de contacto. Por favor, intente de nuevamente mas tarde.'
          );
        }
      );

    }
  }

}
