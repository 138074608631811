<div
  class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-2"
>
  <h2>Compromisos</h2>
  <div class="link-crear">
    <a href="https://forms.gle/DB6CXFzfNv61SKMz5" target="_blank"
      ><span> Proponer un compromiso!</span><span class="ms-1">&rarr;</span></a
    >
  </div>
</div>
<p>
  Espacio para proponer y adherir a Compromisos de la comunidad IMPACT TRADE, ya
  sean generales o entre participantes, de forma de compartir y colaborar en una
  plataforma basada en Blockchain, asegurando inmutabilidad, transparencia y
  trazabilidad.
</p>
<div class="w-100 mb-4">
  <ul>
    <li *ngFor="let comp of compromisosList">
      <h4>{{ comp.title }}</h4>
      <p>{{ comp.desc }}</p>
    </li>
  </ul>
</div>

<button (click)="this.buttonClick()" class="button">
  <span>Ver Compromisos</span>
</button>
