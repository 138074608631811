<div class="divform">
  <div class="forma">
    <form (ngSubmit)="enviarFormulario()" class="forma">
      <h1><strong>Dejanos tu mensaje!</strong></h1>
      <div class="form-group">
        <label for="nombre">Tu nombre</label>
        <input
          type="text"
          name="tu-nombre"
          id="nombre"
          class="form-control"
          [(ngModel)]="nombre"
          (input)="validarInput(nombre, 'nombre')"
        />
        <span [class]="{ opacity: 0 }">{{ errores.nombre }}</span>
      </div>
      <div class="form-group">
        <label for="correo">Correo electrónico</label>
        <input
          type="email"
          name="tu-correo"
          id="correo"
          class="form-control"
          [(ngModel)]="correo"
          (input)="validarInput(correo, 'correo', true)"
        />
        <span [class]="{ opacity: 0 }">{{ errores.correo }}</span>
      </div>
      <div class="form-group">
        <label for="asunto">Asunto</label>
        <input
          type="text"
          name="tu-asunto"
          id="asunto"
          class="form-control"
          [(ngModel)]="asunto"
          (input)="validarInput(asunto, 'asunto')"
        />
        <span [class]="{  opacity: 0 }">{{ errores.asunto }}</span>
      </div>
      <div class="form-group">
        <label for="mensaje">Mensaje</label>
        <textarea
          type="text"
          name="tu-mensaje"
          id="mensaje"
          class="form-control"
          [(ngModel)]="mensaje"
          (input)="validarInput(mensaje, 'mensaje')"
        ></textarea>
        <span [class]="{ opacity: 0 }">{{
          errores.mensaje
        }}</span>
      </div>
      <button type="submit" class="btn">Enviar</button>
      <div class="mt-4 error-general" *ngIf="mostrarMensajeDeError">
        {{
          "Hay uno o más campos con error. Por favor, revísalos e inténtalo de nuevo."
        }}
      </div>
    </form>
  </div>
</div>
