import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss'],
})
export class FeaturedComponent implements OnInit {
  longDate: any;
  constructor(private elementService: ElementService) {}

  img_base = environment.img_base;

  pageIndex = 0;
  desplazamiento = 0;
  limite = 3;
  totalItems = 0;

  elementos$: Elemento[] = [];
  isMobileWidth = false;

  //categorias$: Categoria[];
  //moduloTipos$: ModuloTipo[];

  ngOnInit() {
    if (window.screen.width < 1024) {
      // 768px portrait
      this.isMobileWidth = true;
    }

    //OBTENGO CATEGORIAS
    // this.categoriaService.getAll().subscribe(
    //   data => {
    //       this.categorias$ = data['categorias'];
    //   });

    //      //OBTENGO MODULO-TIPOS
    //      this.moduloTipoService.getAll('zonaDeImpacto').subscribe(
    //       data => {
    //           this.moduloTipos$ = data['moduloTipos'];
    //       });

    //Informaciones

    this.filtrar(false);
  }

  public filtro = new FormGroup({
    nombre: new FormControl(),
    institucion: new FormControl(),
    tipoModulo: new FormControl(),
    categoria3: new FormControl(),
  });

  public filtrar(cargarMas: boolean): void {
    if (!cargarMas) {
      this.pageIndex = 0;
      this.desplazamiento = 0;
    }
    var filtroDestacados: ElementoFiltro = new ElementoFiltro();
    filtroDestacados.modulo = 'Eventos';
    filtroDestacados.categorias = [];
    filtroDestacados.ordenDestacado !== null &&
      filtroDestacados.ordenDestacado > 0 &&
      filtroDestacados.ordenDestacado < 4;

    this.elementService
      .getAll(
        this.limite,
        this.desplazamiento,
        filtroDestacados,
        'fechaInfoInicio-desc',
      )
      .subscribe(data => {
        if (cargarMas) {
          (data as any)['elementos'].forEach((element: Elemento) => {
            this.elementos$.push(element);
          });
        } else {
          this.elementos$ = (data as any)['elementos'];
        }
        this.totalItems = (data as any)['total'];
      });
    this.pageIndex++;
  }
}
