<h2>Galería de Fotos</h2>

<!-- <gallery [items]="items" thumbPosition="left"></gallery> -->
<div class="grid">
  <div
    class="grid-item"
    *ngFor="let item of this.items; let i = index"
    (click)="openModal(i)"
  >
    <img [src]="item.src" />
  </div>
</div>
