import { CategoriaFilterService } from './categoria-filter.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CategoriaElemento } from './shared/models/categoria-elemento.model';

@Pipe({
  name: 'categoriaFilter',
})
export class CategoriaFilterPipe implements PipeTransform {
  //INés_20231201:using tipo_clasificacion + nivel padre == 0 from json
  transform(categorias: CategoriaElemento[], tipo: any): unknown {
    console.log(tipo);
    let result = [];
    if (!!categorias) {
      categorias.forEach((CategoriaElemento) => {
        if (
          tipo === CategoriaElemento.categoria.padre.tipo_clasificacion &&
          CategoriaElemento.categoria.padre.nivel === '0'
        ) {
          result.push(CategoriaElemento.categoria.nombre);
        }
      });
      if (result.length == 0) {
        result.push('');
      }
      // ToDo: INés_20231201 validar con qué caracter concatenar
      // result.sort((a, b) => a.name - b.name)
      //   if (tipo === 'dimension'){
      //     return result.join(' + ');
      //   }
      //   if (tipo == 'ods'){
      //     return result.join(' - ')
      //   }
    }
    return result.join(' / ');
  }

  constructor(private categoriaFilterService: CategoriaFilterService) {}
}
