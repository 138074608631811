import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Compromiso } from 'src/app/shared/models/compromiso.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-commitments',
  templateUrl: './commitments.component.html',
  styleUrls: ['./commitments.component.scss'],
})
export class CommitmentsComponent implements OnInit {
  titulo: string = '';
  compromisosList: Compromiso[] = [];
  community: string | undefined;
  id: number | null = null;
  filters: any = {};
  constructor(
    private compromisoService: CompromisoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];

      switch (this.id) {
        case 1:
          this.titulo =
            'Compromisos de Buen Uso de la Tecnología <br> Technology Force for Good';
          this.community = 'Fibras';
          break;
        case 2:
          this.titulo =
            'Compromisos de Sostenibilidad <br> Ambiental, Social y Gobernanza';
          this.community = 'Fibras';

          break;

      }

      if (this.id == 1) {
        this.loadCompromisos(this.id);
      } else if (this.id == 2) {
        this.loadCompromisos(null);
      } 
    });
  }

  loadCompromisos(id: number | null): void {
    this.compromisoService.getCompromisos(id).subscribe((data: any) => {
      this.compromisosList = data.commitments;
    });
  }
}
