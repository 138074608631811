<div class="d-lg-none">
  <button (click)="this.buttonClick()" class="button-plataforma">
    plataforma blockchain
  </button>
</div>
<aside class="container">
  <div class="col">
    <nav>
      <ul>
        <li
          *ngFor="let item of listItems"
          (click)="this.selectComp(item)"
          [ngClass]="{
            active: this.selected.id === item.id,
            'd-flex': true,
            'flex-row': true
          }"
        >
          <div class="me-lg-2">
            <i [class]="['bi', item.icon]"></i>
          </div>
          <div class="col texto-link">
            {{ item.desc }}
          </div>
        </li>
        <!-- <div class="divider"></div>
        <li>
          <a
            target="_blank"
            href="https://virtual-stage.eventtia.com/es/impacttrade/login"
            class="d-flex flex-row align-items-center"
            ><span class="me-lg-2 eventia-icon">tt</span
            ><span class="d-none d-lg-block">Eventtia App</span>
          </a>
        </li> -->
        <div class="divider d-none d-lg-block"></div>
        <li class="d-none d-lg-block plataforma">
          <a
            (click)="this.buttonClick()"
            class="d-none d-lg-flex flex-row align-items-center"
            ><span class="d-none d-lg-block">Plataforma</span>
          </a>
        </li>
      </ul>
      <div></div>
    </nav>
  </div>
</aside>
