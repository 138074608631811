<section>
    <footer>
        <!-- <div>
            <p>Montevideo, Uruguay</p>
        </div> -->
        <div>
            <ul class="social">
                <li>
                    <a target="_blank" href="https://www.linkedin.com/company/fibras/">
                        <i class="bi bi-linkedin"></i>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/fibras.uy/">
                        <i class="bi bi-instagram"></i>                    </a>
                </li>
                <li>
                    <a target="_blank" href="mailto:gestion@fibras.org">
                        <i class="bi bi-envelope-fill"></i>                    </a>
                </li>
                

            </ul>
            <div class="row w-75 justify-content-center imagenes">
                <p>Powered by Sincronía</p>
                <a target="_blank" href="https://www.sincronia.tech/">
                    <img class="logos" src="/assets/img/ui/logoSincronia.png" alt="" style="width: 80px;">
                </a>    
            </div>
            <div class="container-fluid">
                 <span routerLinkActive="active" routerLink="elements">&copy;{{currentYear}} Fibras</span>
            </div>
        </div>
    </footer>
    <a (click)="scrollToTop()" class="back-to-top">
        <div>
            <i class="bi bi-arrow-up-circle"></i>
            <i class="bi bi-arrow-up-circle-fill"></i>
        </div>
    </a>
</section>