import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { CompromisoType } from 'src/app/modules/communities/types';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';
import {
  CommunityDataAbstractService,
  communityDataToken,
  DataComprimisosType,
} from '../../../community-data';
import { Compromiso } from 'src/app/shared/models/compromiso.model';

@Component({
  selector: 'app-compromisos',
  templateUrl: './compromisos.component.html',
  styleUrls: ['./compromisos.component.scss'],
})
export class CommunityPanelCompromisosComponent {
  esSuscriptor: boolean = false;
  tieneSuscripcionesPendientes: boolean = false;
  token: string = '';
  compromisosList: Compromiso[] = [];

  communityService = inject<CommunityDataAbstractService>(communityDataToken);
  infoCompromisos = this.communityService.getInfo(
    'compromisos',
  ) as DataComprimisosType;

  constructor(
    private router: Router,
    private suscripcionService: SuscripcionService,
    private modalService: NgbModal,
    private keycloakService: KeycloakService,
  ) {
    this.keycloakService.getToken().then(token => (this.token = token));
    this.suscripcionService.isSuscriptor().subscribe(result => {
      this.esSuscriptor = result;
    });

    this.suscripcionService.hasPendingSuscription().subscribe(result => {
      this.tieneSuscripcionesPendientes = result;
    });

    this.getCompromisos();
  }

  async getCompromisos() {
    const data = await this.communityService.getCompromisos();
    this.compromisosList = data.commitments;
  }

  openModal(texto: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
    });
    modalRef.componentInstance.texto = texto;
  }

  buttonClick() {
    if (this.esSuscriptor) {
      if (this.suscripcionService.idClienteConfirmado == null) {
        this.openModal(
          'Aún no tiene aprobado el usuario para acceder a la plataforma. Estará disponible a la brevedad.',
        );
      } else {
        window.open(
          `${environment.url_sincronia}user_id=${this.suscripcionService.idClienteConfirmado}&token=${this.token}`,
          '_blank',
        );
      }
    } else if (this.tieneSuscripcionesPendientes) {
      this.openModal(
        'Usted ya tiene una suscripcion hecha en estado pendiente de verificacion. Pronto le enviaremos novedades de la misma y como proseguir.',
      );
    } else {
      this.router.navigate(['/market']);
    }
  }
}
