export interface Suscripcion {
  id: number;
  idSuscripcionExterno: string;
  codigoPromocion: string;
  tenantProductorId: number;
  objeto: string;
  cliente: Client;
  //contacto: Contact;
  //plan: Plan;
  tienda: Tienda;
  estado: Estado;
  fechaInicioVigencia: Date;
  fechaFinVigencia: Date;
  fechaModificado: Date;
  //complementos: ComplementoRecurso[];
}

export interface Client {
  id: number;
  nombre: string;
  tipo: string;
}

export interface Estado {
  nombre: string;
  idExterno: string;
  permiteCancelacion: boolean;
}

export interface Tienda {
  id: number;
  nombre: string;
}

export enum SuscripcionEstado {
  PENDIENTE = 'Iniciado - Pendiente de verificación',
  CONFIRMADA = 'Confirmado',
}
