import { Component } from '@angular/core';

@Component({
  selector: 'app-proposito',
  templateUrl: './proposito.component.html',
  styleUrls: ['./proposito.component.scss']
})
export class PropositoComponent {

}
