import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbDateStruct,
  NgbPaginationConfig,
  NgbDateAdapter,
  NgbDateNativeAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { JWTContent } from 'src/app/app.component';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';

export interface InvoiceData {
  id: number;
  fechaFactura: Date;
  estado: string;
  importeTotal: number;
  moneda: Moneda;
}

export interface Moneda {
  id: number;
  codigo: string;
  moneda: string;
  simbolo: string;
}

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.page.html',
  styleUrls: ['./invoices.page.scss'],
  providers: [
    NgbPaginationConfig,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    // { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatter }
  ],
})
export class InvoicesPage {
  pageIndex = 1;
  pageSize = 10;
  total!: number;
  data: InvoiceData[] = [];

  displayedColumns: string[] = [
    'invoiceNumber',
    'date',
    'status',
    'value',
    'acciones',
  ];

  estado!: string;
  tipo!: string;
  rangoDias!: string;
  fechaInicio!: NgbDateStruct;
  fechaFin!: NgbDateStruct;
  hasAccess = false;

  @ViewChild('paginator') paginator: any; // Replace 'MatPaginator' with 'any'
  @ViewChild('sort') sort: any; // Replace 'MatSort' with 'any'

  constructor(
    public invoiceService: InvoiceService,
    private paginationConfig: NgbPaginationConfig,
  ) {
    paginationConfig.boundaryLinks = true; // Configure pagination options
    paginationConfig.pageSize = this.pageSize;
  }

  async ngOnInit(): Promise<void> {}

  buscar() {
    this.invoiceService
      .getAll(
        this.pageSize,
        (this.pageIndex - 1) * this.pageSize,
        this.fechaInicio,
        this.fechaFin,
        this.rangoDias,
        this.estado,
      )
      .subscribe(resp => {
        console.log('FACTURAS', resp);
        this.data = resp.cfeCabezales;
        this.total = resp.total;
        console.log(resp);
      });
  }

  paginar(paginator: any) {
    this.pageIndex = paginator;
    this.buscar();
  }

  pagar(invoice: any) {
    this.invoiceService.pagarFactura(invoice['id']).subscribe(
      response => {
        if (response != null && response !== undefined && response !== '') {
          if (response === 'EXITO') {
            console.log('RESPONSE', response);
            //this.wizzard.selected.completed = true;
            //this.wizzard.next();
          } else {
            document.open('text/html');
            document.write(response);
            document.close();
          }
        }
      },
      error => {
        //this.config = new MatSnackBarConfig();
        //this.config.duration = 5000;
        const mensaje = 'Hubo un error al pagar la factura, Intente mas tarde';
        //this.snackbar.open(mensaje, 'Cerrar', this.config);
      },
    );
  }
}
