import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) {
  }

  crear(_idExternoCliente: any, _idReferencia: any, esPersona: any, total: any, importeGravado: any): Observable<any> {
    const _pago = {
        idExternoCliente: _idExternoCliente,
        idReferencia: _idReferencia,
        tipoReferencia: 'FACTURA',
        monedaCodigo: 'UYU',
        importe: total,
        importeGravado: importeGravado,
        consumidorFinal: esPersona,
        fechaVencimiento: '2020-11-30T08:30:00.744-03:00',
        fechaLimitePago: '2020-11-30T08:30:00.744-03:00',
      }
    ;
    return this.httpClient.post(
      environment.api_tienda + '/tiendas/' + environment.idStore + '/pagos', _pago
    );
  }


  public getAll(limite: number, desplazamiento: number, fechaInicio: NgbDateStruct | null, fechaFin: NgbDateStruct | null, rangoDias: string, estado: string | null, tipo: string | null): Observable<any> {

    let params = new HttpParams();

    if (estado == "TODOS") {
      estado = null;
    }

    if (tipo == "TODOS") {
      tipo = null;
    }

    if (fechaInicio != null) {
      params = params.append('fechaDesde', fechaInicio.toString());
    }
    if (fechaFin != null) {
      params = params.append('fechaHasta', fechaFin.toString());
    }

    if (tipo != null) {
      params = params.append('tipoReferencia', tipo);
    }

    if (estado != null) {
      params = params.append('estadoPago', estado);
    }

    if (desplazamiento != null) {
      params = params.append('desplazamiento', desplazamiento.toString());
    }
    if (limite != null) {
      params = params.append('limite', limite.toString());
    }

    return this.httpClient.get<any>( environment.api_tienda + '/tiendas/' + environment.idStore + "/pagos/usuario",
      { params }
    );
  }

}
