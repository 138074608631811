import { Component, inject, Input, OnInit } from '@angular/core';
import { Compromiso } from 'src/app/shared/models/compromiso.model';
import { CompromisoService } from 'src/app/shared/services/compromiso.service';

@Component({
  selector: 'app-commitment-list',
  template: ` <div class="containerList">
    <p class="my-3" *ngIf="!compromisosList.length">
      No se encontraron compromisos
    </p>
    <ul *ngIf="compromisosList.length" class="commitments-list">
      <li class="commitment-header">
        <h3>Titulo</h3>
        <h3>Aplica a</h3>
        <h3>Dimensiones</h3>
      </li>

      <ul
        class="commitment-item"
        *ngFor="let compromiso of compromisosList; let i = index"
      >
        <li routerLink="/commitment-detail/{{ compromiso.code }}">
          <h4>{{ compromiso.title }}</h4>
          <div class="appliesTo">
            <app-svg-icon
              [iconType]="'person'"
              [color]="compromiso.appliesToPersons ? 'currentColor' : '#cfcfcf'"
              matTooltip="Personas"
            ></app-svg-icon>
            <app-svg-icon
              [iconType]="'company'"
              [color]="
                compromiso.appliesToCompanies ? 'currentColor' : '#cfcfcf'
              "
              matTooltip="Empresas"
            ></app-svg-icon>
            <app-svg-icon
              [iconType]="'service'"
              [color]="
                compromiso.appliesToServices ? 'currentColor' : '#cfcfcf'
              "
              matTooltip="Aplicaciones"
            ></app-svg-icon>
          </div>

          <div class="dimensiones">
            <app-svg-icon
              [iconType]="'ambiente'"
              [color]="compromiso.dimensionS ? 'currentColor' : '#cfcfcf'"
              matTooltip="Ambiente"
            ></app-svg-icon>
            <app-svg-icon
              [iconType]="'social'"
              [color]="compromiso.dimensionE ? 'currentColor' : '#cfcfcf'"
              matTooltip="Social"
            ></app-svg-icon>
            <app-svg-icon
              [iconType]="'gobernanza'"
              [color]="compromiso.dimensionG ? 'currentColor' : '#cfcfcf'"
              matTooltip="Gobernanza"
            ></app-svg-icon>
          </div>
        </li>
      </ul>
    </ul>
  </div>`,
  styleUrls: ['./commitment-list.component.scss'],
})
export class CommitmentListComponent {
  @Input() compromisosList: Compromiso[] = [];
}
