<section class="news">

    <div class="section-new">

        <div class="new-title">
            <h2 class="mat-typography__headline">{{elemento.nombre}}
            </h2>
        </div>
        <div class="new">
            <div class="new-information">
                <div class="new-image">
                    <img src="{{img_base}}{{elemento['detailImage']}}" alt="">
                </div>
                <div class="new-copete">
                    <div class="new-date-copete">
                        <p>{{elemento.fechaInfoInicio | date:'d MMM. yyyy'}}</p>
                    </div>


                    <p class="copete">{{elemento.copete}}</p>
                </div>
                <div class="new-description" [innerHTML]="elemento.descripcionHtml | safeHtml">
                </div>
                <li></li>

                <!-- <div class="inscription-button">
                    <a href="{{elemento.link}}" target="_blank">Inscripciones</a>
                </div> -->
            </div>
            <aside>
                <div class="information-table">
                    <p *ngIf="elemento.categorias | categoriaFilter: 'dimension'" class="impact">
                        <strong>Dimensiones:</strong>
                        {{elemento.categorias | categoriaFilter: 'dimension'}}
                    </p>
                    <p *ngIf="elemento.categorias | categoriaFilter: 'ods'" class="ods">
                        <strong>ODS:</strong><br />
                        <span [innerHTML]="elemento.categorias | categoriaImgFilter: 'ods' "></span>
                    </p>
                    <p *ngIf="elemento.lenguaje" class="language"><strong>Lenguaje:</strong>{{elemento.lenguaje}} </p>
                </div>
            </aside>
        </div>
    </div>
</section>