<div [class]="{
  'modal-body': true,
  'warning': this.className === 'warning',
  'alert': this.className === 'alert',
  'success': this.className === 'success',
}">
    <button type="button" class="btn-close" aria-label="Close"
        (click)="modalService.dismiss('Cross click')"></button>
    <div class="row">
        <div class="col">
            <div class="contact-wrap">
                <div class="contents">
                    <div class="title">
                      Estimado usuario:
                    </div>
                    <div class="card-body"><span>{{this.texto}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
