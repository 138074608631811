import {
  Component,
  inject,
  Inject,
  InjectionToken,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { SelectedComponentType, SelectedLink } from '../types';
import {
  CommunityDataAbstractService,
  communityDataToken,
  DataBasicType,
} from './community-data';

@Component({
  selector: 'app-community-panel',
  templateUrl: './community-panel.component.html',
  styleUrls: ['./community-panel.component.scss'],
})
export class CommunityPanelComponent {
  selectedComponent: SelectedComponentType = {
    id: 'iniciativas',
    desc: 'Iniciativas',
  };

  info: DataBasicType =
    inject<CommunityDataAbstractService>(communityDataToken).getCommunityInfo();

  ngOnInit(): void {}

  onSelectedComp(comp: SelectedComponentType) {
    this.selectedComponent = comp;
    if (window.innerWidth > 992)
      window.scrollTo({ top: 450, left: 0, behavior: 'instant' });
    else window.scrollTo({ top: 500, left: 0, behavior: 'instant' });
  }
}
