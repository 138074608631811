<h2>Programa</h2>
<p>
  Conocé la agenda del programa preliminar de la edición 2024 de Impact Trade. ​
</p>

<figure class="d-lg-none">
  <img
    src="../../../../../../../assets/img/imgs/impact-trade/it-programa-1.png"
    alt="Agenda preliminar de la edición 2024 de Impact Trade"
  />
  <img
    src="../../../../../../../assets/img/imgs/impact-trade/it-programa-2.png"
    alt="Agenda preliminar de la edición 2024 de Impact Trade"
  />
  <img
    src="../../../../../../../assets/img/imgs/impact-trade/it-programa-3.png"
    alt="Agenda preliminar de la edición 2024 de Impact Trade"
  />
  <img
    src="../../../../../../../assets/img/imgs/impact-trade/it-programa-4.png"
    alt="Agenda preliminar de la edición 2024 de Impact Trade"
  />

</figure>
<object
  class="d-none d-lg-block"
  data="../../../../../../../assets/img/imgs/impact-trade/Programa IMPACT TRADE Uruguay 2024 .pdf"
  type="application/pdf"
></object>
<p
  class="d-lg-none"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    color: var(--verde-1);
    border: 2px solid var(--verde-1);
    border-radius: 8px;
    margin-top: 10px;
  "
>
  <a
    download
    style="
      display: block;
      text-decoration: none;
      width: 100%;
      text-align: center;
      height: 100%;
      color: var(--verde-1);
      font-weight: 600;
    "
    href="../../../../../../../assets/img/imgs/impact-trade/Programa IMPACT TRADE Uruguay 2024 .pdf"
    >Descargá el programa 2O24</a
  >
</p>
<p class="recomendation">
  <i>
    *Recomendamos alojar el 13/3 y 15/3 en Montevideo. Y el 14/3 en Punta del
    Este (IT incluye traslado el 14/3 am a PDE y vuelta a MDE el 15/3 a la
    tarde/noche)
  </i>
</p>
