import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Elemento } from 'src/app/shared/models/elemento.model';
import { ElementoFiltro } from 'src/app/shared/models/elementoFiltro.model';
import { ElementService } from 'src/app/shared/services/element.service';
import { SuscripcionService } from 'src/app/shared/services/suscripcion.service';
import { environment } from 'src/environments/environment';
import { ProyectDialogComponent } from '../component/proyect-dialog.component';


@Component({
  selector: 'app-proyect-detail',
  templateUrl: './proyect-detail.component.html',
  styleUrls: ['./proyect-detail.component.scss'],
})
export class ProyectDetailComponent implements OnInit {
  idElemento!: string;
  elemento!: Elemento;
  categoria1: string = '';
  categoria2!: string;
  categoria3!: string;

  esSuscriptor: boolean = false;

  img_base = environment.img_base;

  constructor(
    private elementService: ElementService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private location: Location,
    private suscripcionService: SuscripcionService
  ) { }

  ngOnInit(): void {

    this.suscripcionService.isSuscriptor().subscribe((result) => {
      this.esSuscriptor = result;
    });

    this.route.queryParams.subscribe((params) => {
      this.idElemento = this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length
      );
      console.log(this.idElemento);

      // Informaciones
      var filtroDetalle: ElementoFiltro = new ElementoFiltro();
      filtroDetalle.id = Number.parseInt(this.idElemento);
      this.elementService.getAll(0, 0, filtroDetalle, '').subscribe((data) => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(
            this.elemento.fechaInfoInicio
          );

          if (!!this.elemento.categorias) {
            this.elemento.categorias.forEach((categoriaElemento) => {
              if (categoriaElemento.categoria.padre.orden == 1) {
                this.categoria1 += categoriaElemento.categoria.codigo;
              }
              if (categoriaElemento.categoria.padre.orden == 2) {
                this.categoria2 = categoriaElemento.categoria.nombre;
              }
              if (categoriaElemento.categoria.padre.orden == 3) {
                this.categoria3 = categoriaElemento.categoria.nombre;
              }
            });
          }
        }
      });
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openModal(id: number) {
    const modalRef = this.modalService.open(ProyectDialogComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'person--dialog',
      scrollable: true
    });
    modalRef.componentInstance.id = id;
  }

}
