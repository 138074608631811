import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SuscripcionService } from './shared/services/suscripcion.service';
import { style } from '@angular/animations';

export interface JWTContent extends JwtPayload {
  memberId?: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fibras';

  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private suscriptionService: SuscripcionService,
  ) {}

  async ngOnInit() {}
}
