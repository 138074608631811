<main>
  <div class="appDetailsContent">
    <!-- <div class="breadcrumb">
      <div class="container">
        <a [routerLink]="'/'" i18n="Home">Home</a>
        <span> / </span> <span i18n="Manage">Manage</span> <span> / </span> <span i18n="InvoicesRouterLink">Invoices</span>
      </div>
    </div> -->
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 mb-4">
        <h1 class="mat-typography__underscore" i18n="InvoicesTitleInvoices">
          Facturas
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="filtro">
            <label
              class="descCampo"
              i18n="InvoiceType | Filtro Tipo en Facturas"
              >Tipo</label
            >
            <select class="campo form-control" [(ngModel)]="estado">
              <option
                value="TODOS"
                i18n="AllOptionType | Opcion Todos filtro tipo en facturas"
              >
                Todas
              </option>
              <option
                value="LIQUIDADO"
                i18n="LiquidadoOptionStatus | Liquidado"
              >
                Liquidado
              </option>
              <option
                value="FACTURADO"
                i18n="FACTURADOOptionStatus | FACTURADO"
              >
                Facturado
              </option>
              <option value="PAGADO" i18n="COBRADOOptionStatus | COBRADO">
                Pagado
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm">
          <div class="filtro">
            <label i18n="rangoInvoices | Rango" class="descCampo">Rango</label>
            <select class="campo form-control" [(ngModel)]="rangoDias">
              <option i18n="ultimos30dias | ÚLTIMOS 30 DÍAS" value="30">
                Ultimos 30 Dias
              </option>
              <option i18n="ultimos60dias | ÚLTIMOS 60 DÍAS" value="60">
                Ultimos 60 Dias
              </option>
              <option i18n="ultimos30dias | COBRADO" value="90">
                Ultimos 90 Dias
              </option>
              <option i18n="ultimoAnio | ÚLTIMO AÑO" value="365">
                Ultimo Año
              </option>
              <option i18n="personalizado | PERSONALIZADO" value="RANGO">
                Personalizado
              </option>
              <option i18n="ninguno | NINGUNO" value="NINGUNO">
                Sin Rango
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="rangoDias=='RANGO'" class="col-sm">
          <div class="filtro">
            <label
              class="descCampo"
              i18n="CreationDateFilters | Filtro FechasCreacion en Facturas"
              >Fecha Creacion</label
            >
            <div class="input-group">
              <input
                class="form-control"
                placeholder="From"
                i18n-placeholder="desdeInvoices | Desde"
                [(ngModel)]="fechaInicio"
                ngbDatepicker
                #picker="ngbDatepicker"
              />
              <div class="input-group-append" *ngIf="rangoDias=='RANGO'">
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="picker.toggle()"
                  type="button"
                ></button>
              </div>

              <input
                class="form-control"
                placeholder="To"
                i18n-placeholder="hastaInvoices | hasta"
                [(ngModel)]="fechaFin"
                ngbDatepicker
                #picker1="ngbDatepicker"
              />
              <div class="input-group-append" *ngIf="rangoDias=='RANGO'">
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="picker1.toggle()"
                  type="button"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm">
          <div class="botonBusqueda">
            <div class="filtro">
              <button
                class="btn btn-flat btn-accent btn-buscar"
                (click)="buscar()"
                i18n="SearchButtonInvoices | Boton Buscar en Facturas"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- begin Invoices Table -->
    <table class="table table-striped" [ngClass]="'mat-table--numbers'">
      <thead>
        <tr>
          <th
            scope="col"
            mat-sort-header
            i18n="NroInvoiceInvoices | NroFactura columna en facturas"
          >
            N° Factura
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="CreationDateInvoices | FechaCreacion columna en facturas"
          >
            Fecha Creacion
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="StatusInvoices | Estado columna en facturas"
          >
            Estado
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="ValueInvoices | Valor columna en facturas"
          >
            Valor
          </th>
          <th
            scope="col"
            mat-sort-header
            i18n="ActionsInvoices | Acciones columna en facturas"
          >
            Pagar
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of data">
          <td>{{ element.id }}</td>
          <td>{{ element.fechaFactura | date }}</td>
          <td>{{ element.estado }}</td>
          <td>{{ element?.importeTotal }} {{element?.moneda?.codigo}}</td>
          <td class="mat-cell--value">
            <button
              *ngIf=" element?.estado=='LIQUIDADO' || element?.estado=='FACTURADO'"
              (click)="pagar(element)"
              class="btn btn-danger"
              color="primary"
              i18n="btnPayInvoice | Boton pagar Factura"
            >
              Pagar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ngb-pagination
      [collectionSize]="total"
      [(page)]="pageIndex"
      [pageSize]="pageSize"
      [maxSize]="10"
      (pageChange)="paginar($event)"
    >
    </ngb-pagination>
  </div>
</main>
