import { Injectable } from '@angular/core';
import {
  CommunityDataAbstractService,
  DataComprimisosType,
  DataIniciativasType,
  DataParticipantesType,
} from '../community-panel/community-data';
import { SelectedComponentType } from '../types';

@Injectable()
export class ImpactTradeCommunityDataService extends CommunityDataAbstractService {
  override logoAlt: string = '';
  override logoUrl: string =
    '../../../../assets/img/imgs/impact-trade/Logo IMPACT TRADE Uruguay.png';
  protected override nameForCommitmentsApi: string = 'Impact Trade';
  protected override module_prefix: string = 'ImpactTrade';
  protected override api_base: string = '';
  protected override title: string = 'Impact Trade';
  protected override description: string = `Nuestro propósito es acelerar el comercio internacional con impacto social y ambiental a gran escala. IMPACT TRADE es un espacio para que los participantes aumenten sus vínculos de confianza con "jugadores" reconocidos en el comercio internacional de distintas latitudes con los nuevos lentes del impacto.`;
  protected override iniciativas_data: DataIniciativasType = {
    title: 'Iniciativas',
    description: `Espacio para proponer y compartir con la comunidad Iniciativas que surjan en
  IMPACT TRADE de forma de que otros participantes interesados puedan unir
  esfuerzos, ideas e información:`,
    linkProponer: 'https://forms.gle/G7EiqMC9o1ybxpQt5',
  };
  protected override participantes_data: DataParticipantesType = {
    title: 'Participantes',
    description:
      'Información de los participantes que estarán presentes en IMPACT TRADE Uruguay 2024.',
  };
  protected override compromisos_data: DataComprimisosType = {
    title: 'Compromisos',
    description: `Espacio para proponer y adherir a Compromisos de la comunidad IMPACT TRADE, ya
  sean generales o entre participantes, de forma de compartir y colaborar en una
  plataforma basada en Blockchain, asegurando inmutabilidad, transparencia y
  trazabilidad.`,
    linkProponer: 'https://forms.gle/DB6CXFzfNv61SKMz5',
  };
  override sidebarItems: SelectedComponentType[] = [
    { id: 'iniciativas' },
    { id: 'participantes' },
    { id: 'compromisos' },
    { id: 'agenda' },
    { id: 'fotos' },
  ];
}
