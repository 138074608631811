<main>
  <app-header-it></app-header-it>
  <div class="container py-5 mt-n5">
    <div class="row gap-lg-4">
      <div class="col-lg-3 sidebar">
        <app-sidebar (select)="this.onSelectedComp($event)"></app-sidebar>
      </div>
      <div class="col-lg">
        <app-content [selected]="this.selectedComponent"></app-content>
      </div>
    </div>
  </div>
</main>
