import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { Contact } from 'src/app/shared/models/contact.model';
import { Pais } from 'src/app/shared/models/pais.model.';
import { ContactService } from 'src/app/shared/services/contact.service';
import { PaisService } from 'src/app/shared/services/pais.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  styleUrls: ['./user.page.scss'],
})
export class UserPage implements OnInit {
  userForm!: FormGroup;
  user!: Contact;
  paises: Pais[] = [];
  submitted = false;
  paisDomicilio!: Pais;

  constructor(
    private userService: ContactService,
    private paisesService: PaisService,
    private modalService: NgbModal,
  ) {}

  updateUser() {
    // this.userForm = {...this.userForm, codigoPostal: this.userForm.codigoPostal.toString()}
    return this.userService.updateUserWeb(this.userForm.value).subscribe(
      response => this.ngOnInit(),
      error => {},
    );
  }

  idComparator(a: any, b: any): boolean {
    if (a && b) {
      return Number(a['id']) === Number(b['id']);
    }
    return false;
  }

  confirmUpdateUser(userForm: FormGroup) {
    Object.keys(this.userForm.value).forEach(key => {
      if (this.userForm.value[key] === undefined) this.userForm.value[key] = '';
    });
    if (this.userForm.invalid) {
      if (
        userForm.controls['nombre'].invalid ||
        userForm.controls['apellido'].invalid
      ) {
        this.openModal('Nombre y apellido son campos obligatorios', 'warning');
        console.log('invalid');
        return;
      }
    }

    //this.busy = true;
    this.userService.updateUserWeb(this.userForm.value).subscribe(
      response => {
        console.log('OK');
        this.openModal(
          'Los datos de usuario fueron actualizados correctamente',
          'success',
        );
        //this.dialogRef.close('OK');
      },
      error => {
        console.log('Error');
        this.openModal(
          'Hubo un error al intentar actualizar los datos de usuario. Por favor, revise la informacion e intente nuevamente.',
          'alert',
        );
      },
    );
  }

  openModal(texto: string, className?: string) {
    const modalRef = this.modalService.open(ModalDialogComponent, {
      centered: true,
    });
    modalRef.componentInstance.texto = texto;
    modalRef.componentInstance.className = className;
  }

  get f() {
    return this.userForm.controls;
  }

  ngOnInit(): void {
    this.paisesService.getAll().subscribe(
      data => {
        // @ts-ignore
        this.paises = data['paises'] || [];
      },
      error => {
        console.error('Error al obtener países', error);
      },
    );

    this.userForm = new FormGroup({
      email: new FormControl({
        value: '',
        disabled: true,
      }),
      nombre: new FormControl(
        { value: '', disabled: true },
        Validators.required,
      ),
      apellido: new FormControl(
        { value: '', disabled: true },
        Validators.required,
      ),
      telefono: new FormControl({ value: '', disabled: true }, null),
      domicilio: new FormControl({ value: '', disabled: true }, null),
      tipoDocumento: new FormControl({ value: '', disabled: true }, null),
      numeroDocumento: new FormControl({ value: '', disabled: true }, null),
      paisDocumento: new FormControl({ value: '', disabled: true }, null),
      ciudad: new FormControl({ value: '', disabled: true }, null),
      departamento: new FormControl({ value: '', disabled: true }, null),
      paisDomicilio: new FormControl({ value: '', disabled: true }, null),
      codigoPostal: new FormControl({ value: '', disabled: true }, null),
    });
    this.userForm.enable();
    this.userService.getUserWeb().subscribe(data => {
      this.userForm.patchValue({
        email: data.email,
        nombre: data.nombre,
        apellido: data.apellido,
        telefono: data.telefono,
        domicilio: data.domicilio,
        tipoDocumento: data.tipoDocumento,
        numeroDocumento: data.numeroDocumento,
        paisDocumento: data.paisDocumento,
        ciudad: data.ciudad,
        departamento: data.departamento,
        paisDomicilio: data.paisDomicilio,
        codigoPostal: data.codigoPostal,
      });
      this.userForm.controls['email'].disable();
    });
  }
}
